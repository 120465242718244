import "../scss/index.scss";
import {createPopper} from '@popperjs/core';
import {Dropdown, Collapse, Button, Modal} from 'bootstrap';
import Swiper from 'swiper/bundle';
import simpleParallax from 'simple-parallax-js';
// import ScrollReveal from 'scrollreveal';
import { animate, stagger, inView } from "motion"
import Typewriter from 'typewriter-effect/dist/core';
import Headroom from "headroom.js";
import Masonry from "masonry-layout";
import axios from 'axios';
import 'fslightbox';

import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const body = document.querySelector('body');
const header = document.querySelector('header');

const masonry = () => {
    const elem = document.querySelector('.grid');
    if (elem) {
        new Masonry(elem, {
            itemSelector: '.grid-item',
            percentPosition: true,
        });
        elem.style.opacity = 1;
    }
};

const initGsap = () => {
    const banner = document.querySelectorAll('.gsap-img-wrapper');

    if (document.body.contains(banner[0])) {
        banner.forEach(block => {
            let gsapElement = gsap.timeline({
                scrollTrigger: {
                    trigger: block,
                    // endTrigger: bottom,
                    start: "top top",
                    end: "bottom top",
                    scrub: true,
                    // markers: true,
                },
            });
            gsapElement.fromTo(block, {scale: 1}, {scale: (block.classList.contains('gsap-img-wrapper-sm') ? 1.02 : 1.05)})
        });
    }
};

const hamburger = () => {
    const btn = document.querySelector('.hamburger');
    btn.addEventListener('click', () => {
        btn.classList.toggle('is-active');
        body.classList.toggle('menu-open');
        if (body.classList.contains('menu-open')) {
            header.classList.remove('header--unpinned');
            header.classList.add('header--pinned');
        }
    });
};

const scrollReveal = () => {

    const elements = document.querySelectorAll(
        '.sr-item, .sr-item-reversed, .accordion-item:not(.sub-accordion-item)'
    )

    console.log(elements);

    if (elements.length > 0) {
        inView(
            elements,
            (target) => {
                animate(
                    target,
                    { y: 0, opacity: 1, visibility: "visible" },
                    {
                        delay: target.getAttribute('data-animate-delay') ?? .1,
                        duration: target.getAttribute('data-animate-duration') ?? .9,
                    }
                )
            },
        )
    }
};

const initSwiper = () => {
    const defaultSliderQry = document.querySelectorAll('.default-slider');
    if (defaultSliderQry.length) {
        defaultSliderQry.forEach(swiper => {
            swiper.style.opacity = 1;
            if (swiper.querySelectorAll('img').length > 1) {
                const slider = new Swiper(swiper, {
                    slidesPerView: 1,
                    watchOverflow: true,
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true,
                        renderBullet: function (index, className) {
                            return '<span class="' + className + '">' + (index + 1) + "</span>";
                        },
                    },
                });
            } else {
                const slider = new Swiper(swiper, {
                    slidesPerView: 1,
                    watchOverflow: true,
                });
            }
        });
    }

    const contentSliderText = document.querySelectorAll('.content-slider--txt');
    if (contentSliderText.length) {
        contentSliderText.forEach(swiper => {
            swiper.style.opacity = 1;
            const prevButton = swiper.parentNode.querySelector('.swiper-prev');
            const nextButton = swiper.parentNode.querySelector('.swiper-next');
            const slider = new Swiper(swiper, {
                allowTouchMove: false,
                slidesPerView: 1,
                grid: {
                    rows: 4
                },
                watchOverflow: true,
                spaceBetween: 20,
                navigation: {
                    prevEl: prevButton,
                    nextEl: nextButton,
                },
            });
        });
    }

    const contentSlider = document.querySelectorAll('.content-slider--block');

    if (contentSlider.length > 0) {
        contentSlider.forEach(function (item) {
            let categorySlider;
            const constentSliderImages = item.querySelectorAll('.content-slider--imgs');
            if (constentSliderImages.length > 0) {
                constentSliderImages.forEach(swiper => {
                    swiper.style.opacity = 1;
                    categorySlider = new Swiper(swiper, {
                        allowTouchMove: false,
                        effect: 'fade',
                        fadeEffect: {
                            crossFade: true
                        },
                    });
                });
            }
            const myCollapsible = item.querySelector('#cicollapse')
            if (myCollapsible) {
                myCollapsible.addEventListener('show.bs.collapse', function (e) {
                    categorySlider.slideTo(e['target'].getAttribute('id').slice(-1) - 1);
                })
            }
        });
    }

    const imageSliderQry = document.querySelectorAll('.image-slider--swiper');
    if (imageSliderQry.length) {
        imageSliderQry.forEach(swiper => {
            swiper.style.opacity = 1;
            const prevButton = swiper.parentNode.querySelector('.swiper-prev');
            const nextButton = swiper.parentNode.querySelector('.swiper-next');
            const slider = new Swiper(swiper, {
                slidesPerView: 1,
                watchOverflow: true,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                    renderBullet: function (index, className) {
                        return '<span class="' + className + '">' + (index + 1) + "</span>";
                    },
                },
                navigation: {
                    prevEl: prevButton,
                    nextEl: nextButton,
                },
            });
        });
    }

    const activitiesSliderQry = document.querySelectorAll('.activities-slider--swiper');
    if (activitiesSliderQry.length) {
        activitiesSliderQry.forEach(swiper => {
            swiper.style.opacity = 1;
            const slider = new Swiper(swiper, {
                slidesPerView: 2.5,
                watchOverflow: true,
                spaceBetween: 40,
            });
        });
    }

    const newsSliderHomeQry = document.querySelectorAll('.news-reference-slider--swiper');
    if (newsSliderHomeQry.length) {
        newsSliderHomeQry.forEach(swiper => {
            swiper.style.opacity = 1;
            const slider = new Swiper(swiper, {
                slidesPerView: 1,
                watchOverflow: true,
                spaceBetween: 40,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                    renderBullet: function (index, className) {
                        return '<span class="' + className + '">' + (index + 1) + "</span>";
                    },
                },
                breakpoints: {
                    568: {
                        slidesPerView: 1.5,
                    },
                    868: {
                        slidesPerView: 2.2,
                    },
                    1240: {
                        slidesPerView: 3,
                        spaceBetween: 40
                    }
                }
            });
        });
    }

    const historyQry = document.querySelectorAll('.history-swiper');
    if (historyQry.length) {
        historyQry.forEach(swiper => {
            const prevButton = swiper.closest('.history-block').querySelector('.swiper-prev');
            const nextButton = swiper.closest('.history-block').querySelector('.swiper-next');
            swiper.style.opacity = 1;
            const slider = new Swiper(swiper, {
                slidesPerView: 1.2,
                spaceBetween: 40,
                preloadImages: false,
                watchOverflow: true,
                lazy: {
                    loadPrevNext: true, // pre-loads the next image to avoid showing a loading placeholder if possible
                    loadPrevNextAmount: 5, //or, if you wish, preload the next 2 images
                },
                breakpoints: {
                    568: {
                        slidesPerView: 1.8,
                    },
                    650: {
                        slidesPerView: 2.2,
                    },
                    992: {
                        slidesPerView: 'auto',
                    },
                },
                navigation: {
                    prevEl: prevButton,
                    nextEl: nextButton,
                }
            });
        });
    }

    const quotesSliderQry = document.querySelectorAll('.quotes-slider--swiper');
    if (quotesSliderQry.length) {
        quotesSliderQry.forEach(swiper => {
            swiper.style.opacity = 1;

            const slider = new Swiper(swiper, {
                slidesPerView: 1,
                watchOverflow: true,
                effect: 'fade',
                autoHeight: true,
                autoplay: {
                    delay: 4000,
                    pauseOnMouseEnter: true,
                },
                fadeEffect: {
                    crossFade: true
                },
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                    renderBullet: function (index, className) {
                        return '<span class="' + className + '">' + (index + 1) + "</span>";
                    },
                },
            });
        });
    }

    const certificateQry = document.querySelectorAll('.certificate-swiper');
    if (certificateQry.length) {
        certificateQry.forEach(swiper => {
            const prevButton = swiper.closest('.certificate-block').querySelector('.swiper-prev');
            const nextButton = swiper.closest('.certificate-block').querySelector('.swiper-next');
            swiper.style.opacity = 1;
            const slider = new Swiper(swiper, {
                slidesPerView: 1.5,
                spaceBetween: 40,
                preloadImages: false,
                watchOverflow: true,
                lazy: {
                    loadPrevNext: true, // pre-loads the next image to avoid showing a loading placeholder if possible
                    loadPrevNextAmount: 5, //or, if you wish, preload the next 2 images
                },
                breakpoints: {
                    768: {
                        slidesPerView: 2.1,
                    },
                    989: {
                        slidesPerView: 2.6,
                    },
                    1250: {
                        slidesPerView: 3,
                    },
                },
                navigation: {
                    prevEl: prevButton,
                    nextEl: nextButton,
                }
            });
        });
    }
}

const bootstrapValidation = () => {
    var forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    var validation = Array.prototype.filter.call(forms, function (form) {
        form.addEventListener('submit', function (event) {
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            }
            form.classList.add('was-validated');
        }, false);
    });
}

const parallax = () => {
    if (window.innerWidth > 992) {
        document.querySelectorAll('.animate-os-img').forEach(e => {
            e.style.opacity = 1;
            new simpleParallax(e, {
                overflow: false,
                scale: 1.08,
                transition: 'cubic-bezier(0,0,0,1)',
                delay: .2,
            });
        });

        document.querySelectorAll('.animate-os-img-md').forEach(e => {
            e.style.opacity = 1;
            new simpleParallax(e, {
                overflow: false,
                scale: 1.14,
                transition: 'cubic-bezier(0,0,0,1)',
                delay: .2,
            });
        });

        document.querySelectorAll('.animate-os-txt-xs').forEach(e => {
            e.style.opacity = 1;
            new simpleParallax(e, {
                overflow: true,
                scale: 1.06,
                transition: 'cubic-bezier(0,0,0,1)',
                delay: .1,
                orientation: 'down'
            });
        });

        document.querySelectorAll('.animate-os-txt-reversed-xs').forEach(e => {
            e.style.opacity = 1;
            new simpleParallax(e, {
                overflow: true,
                scale: 1.03,
                transition: 'cubic-bezier(0,0,0,1)',
                delay: .1,
                orientation: 'up'
            });
        });

        document.querySelectorAll('.animate-os-txt').forEach(e => {
            e.style.opacity = 1;
            new simpleParallax(e, {
                overflow: true,
                scale: 1.2,
                transition: 'cubic-bezier(0,0,0,1)',
                delay: .1,
                orientation: 'down'
            });
        });

        document.querySelectorAll('.animate-os-txt-reversed').forEach(e => {
            e.style.opacity = 1;
            new simpleParallax(e, {
                overflow: true,
                scale: 1.1,
                transition: 'cubic-bezier(0,0,0,1)',
                delay: .1,
                orientation: 'up'
            });
        });

        document.querySelectorAll('.animate-os-txt-reversed-lg').forEach(e => {
            e.style.opacity = 1;
            new simpleParallax(e, {
                overflow: true,
                scale: 1.3,
                transition: 'cubic-bezier(0,0,0,1)',
                delay: .1,
                orientation: 'up'
            });
        });
    }
};

const headroomInit = () => {
    if (Headroom.cutsTheMustard) {
        if (header) {
            var options = {
                offset: 111,
                classes: {
                    // when element is initialised
                    initial: "header--initial",
                    // when scrolling up
                    pinned: "header--pinned",
                    // when scrolling down
                    unpinned: "header--unpinned",
                    // when above offset
                    top: "header--top",
                    // when below offset
                    notTop: "header--notTop",
                },
            };

            let headroom = new Headroom(header, options);
            headroom.init();

            header.classList.add('is-loaded');
        }
    }
}

const createCookie = (name, value, days) => {
    var date, expires;
    if (days) {
        date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    } else {
        expires = "";
    }
    document.cookie = name + "=" + value + expires + "; path=/";
};

const setPopup = () => {
    const popup = document.getElementById('popup');
    if (document.body.contains(popup)) {
        const myModal = new Modal(popup);

        setTimeout(function () {
            myModal.show();
        }, 2000);

        popup.addEventListener('hidden.bs.modal', function (e) {
            createCookie('popup', 1);
        })

        popup.querySelectorAll('a').forEach((anchor) =>   {
            anchor.addEventListener('click', function (e) {
                createCookie('popup', 1);
            })
        })
    }
};


const headerBgOnHover = () => {
    header.addEventListener('mouseenter', function () {
        header.classList.add('header--dark');
    });
    header.addEventListener('mouseleave', function () {
        setTimeout(function () {
            header.classList.remove('header--dark');
        }, 100);
    });
};

const overlays = document.querySelectorAll('.img-fx-overlay');
const imgAnimations = () => {
    overlays.forEach(function (overlay) {
        if (isElementInViewport(overlay)) {
            if (!overlay.classList.contains('in-viewport')) {
                setTimeout(function () {
                    overlay.classList.add('in-viewport');
                }, 400);
            }
        }
    });
}

const typewriters = document.querySelectorAll('h1[data-typewriter], h2[data-typewriter]');
const typewriterAnimation = () => {
    typewriters.forEach(function (typewriter) {
        new Typewriter(typewriter.querySelector('.typewriter'), {
            strings: typewriter.getAttribute('data-typewriter').split('|'),
            autoStart: true,
            loop: true,
            delay: 100
        });
    });
};

const isElementInViewport = (el, offsetTop) => {
    var rect = el.getBoundingClientRect();

    return rect.bottom > 0 &&
        rect.right > 0 &&
        rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
        rect.top < ((window.innerHeight || document.documentElement.clientHeight) - (offsetTop ? offsetTop : 0));
}

const dropdownActivitiesAnimation = () => {
    const lastItem = document.querySelector('li.entry-13517');

    lastItem.addEventListener('mouseenter', function () {
        if (window.innerWidth > 991.98) {
            header.classList.add('header--dark');
        }
    });
    lastItem.addEventListener('mouseleave', function () {
        if (window.innerWidth > 991.98) {
            header.classList.remove('header--dark');
        }
    });
}

const animateInViewport = () => {
    const items = document.querySelectorAll('.show-interval-vp--wrapper');
    if (document.body.contains(items[0])) {
        items.forEach(e => {
            if (isElementInViewport(e) && !e.classList.contains('in-viewport')) {
                e.classList.add('in-viewport');
            }
        });
    }
};

const integerCounter = () => {
    const wrappers = document.querySelectorAll('.value-wrapper');

    if (document.body.contains(wrappers[0])) {
        wrappers.forEach(wrapper => {
            if (isElementInViewport(wrapper, 100) && !wrapper.classList.contains('in-viewport')) {
                wrapper.classList.add('in-viewport');
                const countupEls = wrapper.querySelectorAll( '.value' );
                countupEls.forEach(animateCountUp);
            }
        });
    }
}


const animationDuration = 2000;
const frameDuration = 1000 / 60;
const totalFrames = Math.round( animationDuration / frameDuration );
const easeOutQuad = t => t * ( 2 - t );
const animateCountUp = el => {
    let frame = 0;
    const countTo = parseInt( el.getAttribute('data-amount'), 10 );
    const counter = setInterval( () => {
        frame++;
        const progress = easeOutQuad( frame / totalFrames );
        const currentCount = Math.round( countTo * progress );

        if ( parseInt( el.innerHTML, 10 ) !== currentCount ) {
            el.innerHTML = currentCount;
        }

        if ( frame === totalFrames ) {
            clearInterval( counter );
        }
    }, frameDuration );
};

const windowOnloadHandler = (e) => {
    scrollReveal();
    initGsap();
    initSwiper();
    bootstrapValidation();
    hamburger();
    headroomInit();
    parallax();
    setPopup();
    imgAnimations();
    typewriterAnimation();
    masonry();
    animateInViewport();
    headerBgOnHover();
    integerCounter();

    body.classList.add('is-loaded');
};

const windowOnscrollHandler = (e) => {
    imgAnimations();
    animateInViewport();
    integerCounter();
}

// window.onresize = windowResizeHandler;
window.onload = windowOnloadHandler;
window.onscroll = windowOnscrollHandler;